@mixin csd-choice-filter($theme, $typo) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark-theme: map-get($theme, is-dark);

  .csd-choice-filter {
    csd-filter-select {
      margin-top: 5px;
    }
  }
}
