@mixin csd-page($theme, $typo) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark-theme: map-get($theme, is-dark);

  .csd-page {
    margin: 0 auto;
    max-width: 1160px;
    padding: 20px;

    .csd-title {
      margin: 20px 0;
      @include mat-typography-level-to-styles($typo, 'display-1');
    }
  }
}
