@mixin csd-button-spinner($theme, $typo) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark-theme: map-get($theme, is-dark);

  .csd-button-spinner {
    width: 16px !important;
    height: 16px !important;
    display: inline-block;
    margin-top: -4px;
    margin-right: 4px;
    margin-left: -9px;
    padding: 0;

    svg {
      width: 100% !important;
      height: 100% !important;

      circle {
        stroke: mat-color($foreground, text);
      }
    }
  }
}
