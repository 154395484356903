// shared across all platforms and apps
@import '~@frontend/scss/index';

/**
 * The following are web specific (used with any web app targets)
 */

// web specific variables
@import 'variables';

// web styles (create/import other scss files or define as needed)
@import 'spacing';
@import 'tags';
