@mixin csd-not-found($theme, $typo) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .csd-not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    .csd-img-wrapper {
      padding: 16px 18px;
      .csd-not-found-img {
        max-width: 100%;
      }
    }
    .csd-text {
      @include mat-typography-level-to-styles($typo, 'title');
      font-weight: normal;
      margin: 40px 0 40px;
      text-align: center;
    }
    .csd-link {
      @include mat-typography-level-to-styles($typo, 'body-1');
      color: mat-color($primary, 'default');
      font-weight: bold;
      text-decoration: none;
    }
  }
}
