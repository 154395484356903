@mixin csd-mat-ripple($theme, $typo) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark-theme: map-get($theme, is-dark);

  .mat-checkbox-inner-container:hover .mat-checkbox-persistent-ripple {
    opacity: 16%;
  }

  .mat-radio-button {
    .mat-radio-container:hover .mat-radio-persistent-ripple {
      opacity: 12%;
    }
    &:not(.mat-radio-checked) .mat-radio-container:hover .mat-radio-persistent-ripple {
      opacity: 5%;
    }
  }
}
