@mixin csd-mat-spinner($theme, $typo) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark-theme: map-get($theme, is-dark);

  .mat-spinner {
    circle {
      stroke: map-get($primary, '300-contrast');
    }
  }
}
