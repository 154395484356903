@mixin csd-mat-buttons($theme, $typo) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark-theme: map-get($theme, is-dark);

  .mat-button,
  .mat-raised-button,
  .mat-flat-button,
  .mat-stroked-button {
    min-height: 36px;
    line-height: 36px;
    padding: 16px;
    text-transform: uppercase;
    @include mat-typography-level-to-styles($typo, 'button');
  }
}
