@mixin csd-request-password-reset($theme, $typo) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark-theme: map-get($theme, is-dark);

  .csd-request-password-reset {
    width: 450px;
    @media (max-width: map-get($csd-breakpoints, tablet)) {
      width: 100%;
    }
    .csd-title {
      @include mat-typography-level-to-styles($typo, 'headline');
      margin: 0;
    }
    .csd-form-step {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      align-content: space-around;
      .csd-description {
        color: mat-color($primary, '50-contrast');
      }
      form {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        align-content: space-around;
        .mat-form-field {
          margin-bottom: 20px;
        }
        .csd-request-reset-button {
          align-self: flex-end;
        }
      }
    }
    .csd-success-step {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      align-content: space-around;
      .csd-description {
        color: mat-color($primary, '50-contrast');
      }
      .csd-back-to-login {
        align-self: flex-end;
      }
    }
  }
}
